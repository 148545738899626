import clsx from 'utils/clsx'
import GenericPopup, {
  GenericPopupContentProps,
  GenericPopupHeaderProps,
  GenericPopupRootProps,
} from '@integration-app/ui/Popup'
import { HBox } from '@integration-app/ui/Layout'
import classes from './ActionConfigurationPopup.module.css'
import { PropsWithChildren } from 'react'

export type PopupRootProps = GenericPopupRootProps

type ActionConfigurationPopupRootComponentType = (
  props: GenericPopupRootProps,
) => JSX.Element | null

type PopupComponentType = ActionConfigurationPopupRootComponentType & {
  Header: (props: GenericPopupHeaderProps) => JSX.Element | null
  Content: (props: GenericPopupContentProps) => JSX.Element | null
}

function PopupRoot({ children, className, ...props }: GenericPopupRootProps) {
  return (
    <GenericPopup className={clsx(classes.popup, className)} {...props}>
      {children}
    </GenericPopup>
  )
}

function PopupContent({
  children,
  className,
  ...props
}: GenericPopupContentProps) {
  return (
    <GenericPopup.Content
      className={clsx(classes.content, className)}
      {...props}
    >
      <HBox>{children}</HBox>
    </GenericPopup.Content>
  )
}

function PopupHeader({
  children,
  className,
  ...props
}: GenericPopupHeaderProps) {
  return (
    <GenericPopup.Header className={clsx(classes.header, className)} {...props}>
      {children}
    </GenericPopup.Header>
  )
}

export const ActionConfigurationPopup: PopupComponentType = Object.assign(
  PopupRoot,
  {
    Content: PopupContent,
    Header: PopupHeader,
  },
)

export function LeftPanel({
  children,
  className,
}: PropsWithChildren<{
  className?: string
}>) {
  return <div className={clsx(classes.leftPanel, className)}>{children}</div>
}
export function RightPanel({
  children,
  className,
}: PropsWithChildren<{
  className?: string
}>) {
  return <div className={clsx(classes.rightPanel, className)}>{children}</div>
}
